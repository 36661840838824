import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/Seo';

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <div className="page-wrapper-inner">
      <Seo
        title="404 Not Found"
      />
      <section className="wrapper style1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>NOT FOUND</h1>
              <p>Sorry, that page doesn't exist.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
